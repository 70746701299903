<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <molecules-summary-card
          icon="account-multiple"
          :title="`${$store.state.captain.total} Kapten`"
          :desc="`Sampai Tanggal ${$moment().format('DD MMMM YYYY')}`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <atoms-text class="pa-2"><v-icon left>mdi-magnify</v-icon><span>Pencarian</span></atoms-text>
      <v-row justify="space-between">
        <v-col cols="12" md="8">
          <atoms-text-field
            label="Cari Kapten"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="6" md="2">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Kapten</atoms-button
          >
        </v-col>
        <v-col cols="6" md="2">
          <atoms-button @click="showAdd = true" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-plus</v-icon>Sesuaikan Gudang Kapten</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getWorkers()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tanggal Daftar : {{ sort }}</atoms-button
          >
        </v-col>
        <v-col cols="12" md="auto">
          <!-- <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /> -->
        </v-col>
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div
          v-if="
            $store.state.captain.data &&
            $store.state.captain.data.filter((x) => x.captain) &&
            $store.state.captain.data.filter((x) => x.captain).length > 0
          "
        >
          <div
            v-for="({ ...rest }, i) in ($store.state.captain.data &&
              $store.state.captain.data.filter((x) => x.captain)) ||
            []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink">
                <v-avatar color="grey lighten-4 rounded-lg">
                  <atoms-image :enableZoom="true" v-if="rest.photo" :src="rest.photo" />
                  <v-icon light v-else>mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col cols="8" md="4">
                <atoms-text :auto="true" class="font-weight-bold">{{
                  $toCapitalize((rest.fullName && rest.fullName) || 'Pekerja')
                }}</atoms-text>
                <atoms-text class="primary--text">{{ (rest && rest.email) || 'pekerja.email@website.com' }}</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold"
                  >Pekerja sejak {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
                <atoms-text class="primary--text"
                  >Akun diperbarui {{ $moment(rest && rest._updatedDate).format('dddd, DD MMMM YYYY') }}</atoms-text
                >
              </v-col>
              <v-col align="center" v-if="rest.captain">
                <v-chip small class="rounded-lg" color="primary">Kapten</v-chip>
              </v-col>
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item link @click="toggleCaptain(rest, false)" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-close-circle</v-icon>
                      <v-list-item-title class="px-2">{{
                        rest.captain ? 'Nonaktifkan' : 'Aktifkan'
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showEdit = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-account-details</v-icon>
                      <v-list-item-title class="px-2">Detail</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-container class="px-0 pb-0">
              <atoms-text
                ><v-icon left>mdi-map-marker</v-icon>
                {{ rest.address && rest.address.streetAddress && rest.address.streetAddress + ',' }}
                {{ rest.address && rest.address.city && rest.address.city + '-' }}
                {{ (rest.address.province && rest.address.province) || 'Provinsi Tidak Dicantumkan' }}</atoms-text
              >
            </v-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.captain.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada kapten yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <v-slide-x-reverse-transition>
      <organism-captains-detail
        v-if="showEdit"
        :id="showEdit"
        @closed="
          () => {
            showEdit = null
          }
        "
      /><organism-captains-register
        v-if="showAdd"
        @closed="
          () => {
            showAdd = false
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      term: '',
      sort: 'Desc',
      page: 1,
      limit: 5,
      showAdd: false,
      showEdit: null,
      constant: ['Desc', 'Asc'],
      reportLoading: false,
      mainLoading: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.getWorkers()
      },
      deep: true,
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.page = 1
      this.getWorkers()
      this.getSummary()
    },
    async getSummary() {
      this.reportLoading = true
      try {
        await this.$store.dispatch('captain/summary')
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async toggleCaptain(payload, value) {
      this.$store.commit('SET', { loading: true })
      try {
        if (payload) {
          await this.$store.dispatch('captain/update', {
            ...(payload || {}),
            captain: value,
            captainAreas: [],
          })
          this.$showDialog({
            title: 'Berhasil',
            body: 'Pekerja berhasil dinonaktifkan sebagai kapten',
          })
          this.loadData()
        }
      } catch (error) {
        alert(error || '')
      }
      this.$store.commit('SET', { loading: false })
    },
    async getWorkers() {
      this.mainLoading = true
      const term = { $regex: this.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('captain/getAll', {
          query: {
            captain: true,
            captainAreas: { $exists: true },
            $or: [
              {
                idNumber: term,
              },
              {
                fullName: term,
              },
              {
                email: term,
              },
              {
                phone: term,
              },
              {
                birthplace: term,
              },
              {
                'address.city': term,
              },
              {
                'address.province': term,
              },
              {
                'address.kecamatan': term,
              },
            ],
          },
          custom: {
            pipeline: [
              {
                $sort: {
                  _createdDate: this.sort === this.constant[0] ? -1 : 1,
                },
              },
            ],
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
